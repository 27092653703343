import { PdfViewer } from '@blissbook/ui/lib/pdf'
import React from 'react'
import ReactDOM from 'react-dom'

const pdfEls = document.getElementsByTagName('rw-pdf')
for (const pdfEl of pdfEls) {
  const props = {
    url: pdfEl.getAttribute('src'),
    width: Number.parseInt(pdfEl.style.width, 10),
    height: Number.parseInt(pdfEl.style.height, 10),
  }

  ReactDOM.render(<PdfViewer {...props} />, pdfEl)
}
